import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '~/environments/environment';

export const API_ENDPOINT: Readonly<string> = `${environment.configuration.apiHost}${environment.configuration.apiEndpoint}`;

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(private http: HttpClient) {}

  public get<T>(url: string, params?: any, customHeaders?: { [key: string]: string }): Observable<T> {
    const headers: HttpHeaders = this.getHeaders(customHeaders);
    return this.http.get<T>(API_ENDPOINT + url, { headers, params }).pipe(first());
  }

  public getAll<T>(url: string, params?: any, customHeaders?: { [key: string]: string }): Observable<T[]> {
    const headers: HttpHeaders = this.getHeaders(customHeaders);
    return this.http.get<T[]>(API_ENDPOINT + url, { headers, params }).pipe(first());
  }

  public delete<T>(url: string, params?: any, customHeaders?: { [key: string]: string }): Observable<T> {
    const headers: HttpHeaders = this.getHeaders(customHeaders);
    return this.http.delete<T>(API_ENDPOINT + url, { headers, params }).pipe(first());
  }

  public post<T, U>(url: string, body?: U, params?: any, customHeaders?: { [key: string]: string }): Observable<T> {
    const headers: HttpHeaders = this.getHeaders(customHeaders);
    return this.http.post<T>(API_ENDPOINT + url, body, { headers, params }).pipe(first());
  }

  public postFormData<T, U>(url: string, body: U, params?: any): Observable<T> {
    return this.http.post<T>(API_ENDPOINT + url, body, { params }).pipe(first());
  }

  public put<T, U>(url: string, body: U, params?: any, customHeaders?: { [key: string]: string }): Observable<T> {
    const headers: HttpHeaders = this.getHeaders(customHeaders);
    return this.http.put<T>(API_ENDPOINT + url, body, { headers, params }).pipe(first());
  }

  private getHeaders(customHeaders?: { [key: string]: string }): HttpHeaders {
    return new HttpHeaders({ 'Content-Type': 'application/json', ...customHeaders });
  }
}
