import { PromoStatus } from '../enums';
import { IPromotion } from '../interfaces';

function getSPEquivalentValues(p: IPromotion): {
  code: string;
  nip: string;
  nbGratuits: string;
  nbPayants: string;
  nbProduits: string;
  pourcentage: string;
  remise: string;
  mecanique: string;
} {
  switch (p.newMechanic) {
    case 'carte':
      return {
        code: '8',
        nip: 'Cagnottage / Cartes (% du PVC)',
        nbGratuits: '',
        nbPayants: '',
        nbProduits: '',
        pourcentage: p.newGenerosity?.toFixed(2) ?? '',
        remise: '',
        mecanique: [20, 25, 30, 34].includes(p.newGenerosity ?? 0) ? `FID ${p.newGenerosity}%` : ''
      };
    case '3e_gratuit':
      return {
        code: '2',
        nip: 'Lot virtuel : le 3ème gratuit',
        nbGratuits: '1',
        nbPayants: '2',
        nbProduits: '',
        pourcentage: '',
        remise: '',
        mecanique: 'LV 2+1'
      };
    case 'lv<67':
      return {
        code: '6',
        nip: 'Lot virtuel : le Xème à Y%',
        nbGratuits: '',
        nbPayants: '',
        nbProduits: '2',
        pourcentage: ((p.newGenerosity ?? 0) * 2).toFixed(2) ?? '',
        remise: '',
        mecanique: [25, 30].includes(p.newGenerosity ?? 0) ? `LV 2ème -${(p.newGenerosity ?? 0) * 2}%` : ''
      };
    case 'lv>67':
      return {
        code: '6',
        nip: 'Lot virtuel : le Xème à Y%',
        nbGratuits: '',
        nbPayants: '',
        nbProduits: '2',
        pourcentage: ((p.newGenerosity ?? 0) * 2).toFixed(2) ?? '',
        remise: '',
        mecanique: [34].includes(p.newGenerosity ?? 0) ? `LV 2ème -${(p.newGenerosity ?? 0) * 2}%` : ''
      };
    case 'ri<34':
      return {
        code: '11',
        nip: 'Remise immédiate (% du PVC)',
        nbGratuits: '',
        nbPayants: '',
        nbProduits: '',
        pourcentage: p.newGenerosity?.toFixed(2) ?? '',
        remise: '',
        mecanique: [15, 20, 25, 30, 33].includes(p.newGenerosity ?? 0) ? `RI ${p.newGenerosity}%` : ''
      };
    case 'ri>34':
      return {
        code: '11',
        nip: 'Remise immédiate (% du PVC)',
        nbGratuits: '',
        nbPayants: '',
        nbProduits: '',
        pourcentage: p.newGenerosity?.toFixed(2) ?? '',
        remise: '',
        mecanique: [34].includes(p.newGenerosity ?? 0) ? `RI ${p.newGenerosity}%` : ''
      };
    default:
      return {
        code: '',
        nip: '',
        nbGratuits: '',
        nbPayants: '',
        nbProduits: '',
        pourcentage: '',
        remise: '',
        mecanique: ''
      };
  }
}

export namespace PromotionBusinessLogic {
  export const promotionExportMap: { [key: string]: (p: IPromotion) => string | number } = {
    'ID Promotion': (p) => p.idPromotion,
    'Statut OP': (p) => p.status,
    'Phase retenue': (p) => p.retainedPhase,
    'Date/Heure': (p) => (p.rmeValidatedAt ? new Date(p.rmeValidatedAt).toLocaleString('en-GB', { timeZone: 'UTC' }) : ''),
    'Modifié par': (p) => p.rmeValidatedBy,
    'Post Eval Confirme- Promo Opt': (p) => (p.evalType === 'post_eval' && p.status === PromoStatus.Confirme ? 1 : 0),
    'Post Eval Facturee- Promo Opt': (p) => (p.evalType === 'post_eval' && p.status === PromoStatus.Facture ? 1 : 0),
    'Pre Eval - Promo Opt': (p) => (p.evalType === 'pre_eval' ? 1 : 0),
    'Taux remontée réel': (p) => (p.evalType === 'post_eval' ? p.destructionRate : ''),
    'To Sell Out Panel Réel': (p) => (p.evalType === 'post_eval' ? p.sellOutPromoEki : ''),
    'Taux volume incrémental commande': (p) =>
      p.evalType === 'post_eval' && p.retainedPhase === 'commande' ? p.incrementalBrand * 100 : '',
    'Taux volume incrémental réel': (p) => (p.evalType === 'post_eval' && p.retainedPhase === 'reel' ? p.incrementalBrand * 100 : ''),
    'Taux incrémental retailer': (p) => (p.evalType === 'post_eval' ? p.incrementalRetailer : ''),
    'PVC réel (A3D)': (_p) => /*p.evalType === 'post_eval' ? p.grossPrice : */ '',
    'Taux remontée': (p) => (p.evalType === 'pre_eval' ? p.newDestructionBrandMean ?? p.destructionBrandMean ?? '' : ''),
    'Taux volume incrémental prévu': (p) =>
      p.evalType === 'pre_eval'
        ? p.newIncrementalRate
          ? p.newIncrementalRate * 100
          : p.incrementalRate
          ? p.incrementalRate * 100
          : ''
        : '',
    'Taux profiteur': (p) =>
      p.evalType === 'post_eval' && p.retainedPhase !== 'reel'
        ? p.profiteurRate
        : p.evalType === 'pre_eval'
        ? (p.newProfiteurRateReel ?? p.profiteurRateReel) * 100
        : '',
    'Code Mécanique NIP': (p) => (p.evalType === 'pre_eval' ? getSPEquivalentValues(p).code : ''),
    'Mécanique NIP': (p) => (p.evalType === 'pre_eval' ? getSPEquivalentValues(p).nip : ''),
    'Nb Gratuits': (p) => (p.evalType === 'pre_eval' ? getSPEquivalentValues(p).nbGratuits : ''),
    'Nb Payants': (p) => (p.evalType === 'pre_eval' ? getSPEquivalentValues(p).nbPayants : ''),
    'Nb Produits': (p) => (p.evalType === 'pre_eval' ? getSPEquivalentValues(p).nbProduits : ''),
    '% en PVC': (p) => (p.evalType === 'pre_eval' ? getSPEquivalentValues(p).pourcentage : ''),
    'Remise en €/UC': (p) => (p.evalType === 'pre_eval' ? getSPEquivalentValues(p).remise : ''),
    Mecanique: (p) => (p.evalType === 'pre_eval' ? getSPEquivalentValues(p).mecanique : ''),
    Commentaire: (p) => p.comment || ''
  };

  export const validatedExportMap: { [key: string]: (p: IPromotion) => string | number } = {
    'ID Promotion': (p) => p.idPromotion,
    Marque: (p) => p.brand?.name ?? p.brandId ?? ''
  };
}
